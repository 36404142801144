// @ts-check
import './src/polyfills';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { UserProvider } from './src/context/UserContext';
import { CookieLayer } from './src/components/cookie-layer';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import { UnsupportedBrowserWarning } from './src/components/unsupported-browser-warning';
import { WindowSizeProvider } from './src/context/WindowSizeContext';

const cookies = new Cookies();
const googleAnalyticsCookieName = 'gatsby-gdpr-google-analytics';
const googleAnalyticsTrackingId = 'UA-66788249-10';

export const onClientEntry = () => {
  // check if the tracking cookie exists
  if (cookies.get(googleAnalyticsCookieName) === 'true') {
    // initialize google analytics with the correct ga tracking id
    ReactGA.initialize(googleAnalyticsTrackingId);
  }
};

export const onRouteUpdate = ({ location }) => {
  // check if the tracking cookie exists
  if (cookies.get(googleAnalyticsCookieName) === 'true') {
    ReactGA.set({ page: location.pathname, anonymizeIp: true });
    ReactGA.pageview(location.pathname);
  }
};

export const wrapRootElement = ({ element }) => {
  return (
    <UserProvider>
      <WindowSizeProvider>
        {process.env.NODE_ENV === 'production' && (
          <CookieLayer
            onAccept={() => {
              cookies.set(googleAnalyticsCookieName, 'true');
              ReactGA.initialize(googleAnalyticsTrackingId);
            }}
            initialVisible={cookies.get(googleAnalyticsCookieName) !== 'true'}
          />
        )}
        <UnsupportedBrowserWarning />
        {element}
      </WindowSizeProvider>
    </UserProvider>
  );
};
