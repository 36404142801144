// @ts-check
/**
 * @typedef {import("./user.js").UserResult} UserResult
 * @typedef {{ category: string, title: string, tags?: string[], content?: string, coordinates?: {latitude: number, longitude: number}, image?: string, variant?: string, email?: string, alias?: string, gender?: string, birthyear?: string, city?: string, captchaToken: string, commentmail?: string }} PostData
 * @typedef {{ user: UserResult, comments: number, created: string, likes: number, liked: boolean, own: boolean, slug: string, visible: boolean} & PostData} PostResult
 * @typedef {{ category?: string, variant?: string, tags?: string[], number?: number, page?: number}} ListParams variant may have a wildcard in form of *
 * @typedef {{ numItems: number, numPage: number, numItemsAll: number, items: PostResult[]}} PostListResult
 * @typedef {{ comment: string, created: string, likes: number, liked: boolean, slug: string, user: UserResult, visible?: boolean}} CommentObject
 * @typedef {{ file: File, mode: string }} ImageData
 */
import { API_ENDPOINT } from '.';

class PostError extends Error {}
class PostListError extends Error {}

/**
 * Calls the API to upload an image
 * @param {ImageData} params
 * @return {Promise<[{fileName: string}, PostError]>}
 */
export const uploadImage = ({ file, mode }) => {
  const data = new FormData();
  data.append('mode', mode);

  return new Promise(resolve => {
    // first, turn the base64 image into a blob
    fetch(file)
      .then(res => res.blob())
      .then(imageBlob => {
        data.append('file', imageBlob);
        return fetch(`${API_ENDPOINT}/api/file`, {
          method: 'POST',
          credentials: 'include',
          body: data
        })
          .then(response => {
            return response.json();
          })
          .then(jsonData => {
            return resolve([{ fileName: jsonData.file }, undefined]);
          })
          .catch(err => {
            console.error(err);
            return resolve([
              undefined,
              new PostError('Beim Bild-Upload ist etwas schief gelaufen.')
            ]);
          });
      });
  });
};

/**
 * Calls the API to create a new post
 * @param {PostData} newPost
 * @return {Promise<[PostResult, PostError]>}
 */
export const createPost = ({
  category,
  title,
  tags,
  content,
  coordinates,
  image,
  variant,
  email,
  alias,
  gender,
  birthyear,
  city,
  captchaToken,
  commentmail
}) => {
  return new Promise(resolve => {
    return fetch(`${API_ENDPOINT}/api/post`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        category,
        title,
        tags,
        content,
        coordinates,
        image,
        variant,
        email,
        alias,
        gender,
        birthyear,
        city,
        captchaToken,
        commentmail
      })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          case 406:
            return resolve([
              undefined,
              new PostError('Es ist ein Fehler beim Captcha aufgetreten.')
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(post => {
        // TODO: make sure that the result is in fact a PostResult object
        return resolve([post, undefined]);
      })
      .catch(e => {
        console.error(e); // we don't wanna swallow the error
        return resolve([
          undefined,
          new PostError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};

/**
 * Calls the API to get a list of posts, can be filtered
 * @param {ListParams} params
 * @return {Promise<[PostResult, PostError]>}
 */
export const getPosts = params => {
  let queryParams = '';

  // generate query params from params
  for (const key in params) {
    if (queryParams !== '') {
      queryParams += '&';
    }
    queryParams += key + '=' + encodeURIComponent(params[key]);
  }

  if (queryParams.length > 0) queryParams = '?' + queryParams;

  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post${queryParams}`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostListError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new PostListError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(postList => {
        // TODO: make sure that the result is in fact a PostListResult object
        return resolve([postList, undefined]);
      })
      .catch(e => {
        console.error(e); // we don't wanna swallow the error
        return resolve([
          undefined,
          new PostError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};

/**
 * likes a post by its slug
 * @param {{slug: string}} param0
 * @returns {Promise<[PostResult, PostError]>}
 */
export const likePost = ({ slug }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post/${slug}/like`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(post => {
        return resolve([post, undefined]);
      });
  });
};

/**
 * @param {Object} param0
 * @param {string} param0.slug The post slug
 * @param {boolean} param0.visible Whether it should be visible or not
 */
export const setPostVisibility = ({ slug, visible }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post/${slug}`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'PATCH',
      body: JSON.stringify({ visible: visible })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(post => {
        return resolve([post, undefined]);
      });
  });
};

/**
 * blames a post by its slug
 * @param {{slug: string, reason?: string, email?: string, captchaToken: string}} param0
 * @returns {Promise<[PostResult, PostError]>}
 */
export const blamePost = ({ slug, reason, email, captchaToken }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post/${slug}/blame`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: Boolean(reason) ? 'POST' : 'GET',
      body: !Boolean(reason)
        ? undefined
        : JSON.stringify({
            reason: reason,
            email: email,
            captchaToken: captchaToken
          })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          case 406:
            return resolve([
              undefined,
              new PostError('Es ist ein Fehler beim Captcha aufgetreten.')
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(post => {
        return resolve([post, undefined]);
      });
  });
};

/**
 * blames a post by its slug
 * @param {{slug: string, messageBody?: string, email?: string, phone?: string, captchaToken: string}} param0
 * @returns {Promise<[PostResult, PostError]>}
 */
export const contactPost = ({ slug, email, phone, messageBody, captchaToken }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post/${slug}/contact`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: !Boolean(messageBody)
        ? undefined
        : JSON.stringify({
            email: email,
            phone: phone,
            messageBody: messageBody,
            captchaToken: captchaToken
          })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 400:
            return resolve([
              undefined,
              new PostError('Es ist ein Fehler beim Versand aufgetreten.')
            ]);
          case 406:
            return resolve([
              undefined,
              new PostError('Es ist ein Fehler beim Captcha aufgetreten.')
            ]);
          case 500:
            return resolve([
              undefined,
              new PostError('Überprüfe bitte ob alle Felder korrekt ausgefüllt sind.')
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(post => {
        return resolve([post, undefined]);
      });
  });
};

/**
 * comments a post by its slug
 * @param {{slug: string, comment: string, email: string, captchaToken: string}} param0
 * @return {Promise<[CommentObject, PostError]>}
 */
export const commentPost = ({ slug, comment, email, captchaToken }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post/${slug}/comment`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({ text: comment, email: email, captchaToken: captchaToken })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          case 406:
            return resolve([
              undefined,
              new PostError('Es ist ein Fehler beim Captcha aufgetreten.')
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(comment => {
        return resolve([comment, undefined]);
      });
  });
};

/**
 * gets all comments to a post by slug
 * @param {{slug: string}} param0
 * @return {Promise<[CommentObject[], PostError]>}
 */
export const getCommentsPost = ({ slug }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post/${slug}/comment`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(comments => {
        return resolve([comments, undefined]);
      });
  });
};

/**
 * likes a comment by its slug
 * @param {{slug: string}} param0
 * @return {Promise<[CommentObject, PostError]>}
 */
export const likeComment = ({ slug }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/comment/${slug}/like`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(comment => {
        return resolve([comment, undefined]);
      });
  });
};

/**
 * blames a comment by its slug
 * @param {{slug: string, reason?: string, email?: string, captchaToken: string}} param0
 */
export const blameComment = ({ slug, reason, email, captchaToken }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/comment/${slug}/blame`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: Boolean(reason) ? 'POST' : 'GET',
      body: Boolean(reason)
        ? JSON.stringify({
            reason: reason,
            email: email,
            captchaToken
          })
        : undefined
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          case 406:
            return resolve([
              undefined,
              new PostError('Es ist ein Fehler beim Captcha aufgetreten.')
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(comment => {
        return resolve([comment, undefined]);
      });
  });
};

/**
 * validates a post by its slug
 * @param {{slug: string, token: string}} param0
 * @returns {Promise<[PostResult, PostError]>}
 */
export const validatePost = ({ slug, token }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post/${slug}/validate/${token}`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(post => {
        return resolve([post, undefined]);
      });
  });
};

/**
 * deletes a post by its slug
 * @param {{slug: string, token: string}} param0
 * @returns {Promise<[PostResult, PostError]>}
 */
export const deletePost = ({ slug, token }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/post/${slug}/delete/${token}`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new PostError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new PostError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(post => {
        return resolve([post, undefined]);
      });
  });
};
