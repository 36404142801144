// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-feedback-js": () => import("./../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-imprint-js": () => import("./../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-map-js": () => import("./../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-redeem-js": () => import("./../src/pages/redeem.js" /* webpackChunkName: "component---src-pages-redeem-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-sicherheitshinweise-js": () => import("./../src/pages/sicherheitshinweise.js" /* webpackChunkName: "component---src-pages-sicherheitshinweise-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-workshop-js": () => import("./../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */)
}

