import React, { Component } from 'react';
import { Lightbox } from './lightbox';
import { WindowSizeContext } from '../context/WindowSizeContext';

export class UnsupportedBrowserWarning extends Component {
  state = {
    isIE11: false,
    isSafari: false,
    isIOs: false,
    lightboxVisible: false
  };

  componentDidMount() {
    this.setState({
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      isIE11: !!window.MSInputMethodContext && !!document.documentMode,
      isIOs: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
    });
  }

  render() {
    const { isSafari, isIE11, isIOs, lightboxVisible } = this.state;

    return (
      <WindowSizeContext.Consumer>
        {({ lightboxMobile }) => {
          if (isIE11) {
            return (
              <Lightbox
                usePanZoom={false}
                closable={true}
                content={
                  <div style={{ padding: '20vh 10vw' }}>
                    <span
                      style={{
                        marginBottom: '30px',
                        display: 'block',
                        borderTop: '40px solid rgb(204,204,204)',
                        padding: '15px 15px 5px',
                        background: '#fff',
                        textAlign: 'center',
                        fontSize: 40,
                        lineHeight: 1.1
                      }}
                    >
                      <strong
                        style={{
                          marginBottom: '32px',
                          display: 'block'
                        }}
                      >
                        Der Internet Explorer wird nicht unterstützt.
                      </strong>
                    </span>
                  </div>
                }
              >
                {({ openLightbox }) => {
                  if (!lightboxVisible) {
                    this.setState({ lightboxVisible: true }, () => {
                      openLightbox();
                    });
                  }
                }}
              </Lightbox>
            );
          }
        }}
      </WindowSizeContext.Consumer>
    );
  }
}
